.vc-status-green {
    background-color: lightgreen;
}

.vc-status-yellow {
    background-color: yellow;
}

.vc-status-orange {
    background-color: orange;
}

.vc-status-red {
    background-color: lightcoral;
}

.vc-status-pink {
    background-color: pink;
}