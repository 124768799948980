.dnd-calendar-button-display {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

.dnd-calendar-container {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

.dnd-calendar-status {
    margin-top: 8px;
    height: 28px
}

.dnd-calendar-controls {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

.dnd-calendar-display {
    width: 175px;
    text-align: center;
}