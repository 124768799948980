.notes-list-container {
    padding-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

.notes-create-button {
    margin-bottom: 16px;
}