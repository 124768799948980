.calendar-table {
    border: 1px solid black;
    border-spacing: 0;
}

.calendar-header-cell {
    border: 1px solid black;
    padding: 4px;
    text-align: center;
    vertical-align: middle;
}

.calendar-cell {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    vertical-align: top;
    width: 5vw;
    height: 5vw;
}

.calendar-cell-gray {
    color: lightgray;
}

.calendar-cell-highlight {
    border: 1px solid red;
}

.calendar-title {
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    margin-bottom: 8px;
}

.calendar-title-text {
    margin-left: 8px;
}

.calendar-title-buttons {
    float: right;
    margin-right: 8px;
}

.calendar-month-button {
    user-select: none;
}

.calendar-month-button:hover {
    fill: gray;
}