.notes-container {
    padding: 2rem;
    /* background-color: rgb(24, 26, 27); */
    /* height: calc(100vh - 112px); */
    display: flex;
    flex-flow: column;
    margin-bottom: 50vh;
}

.notes-title-input {
    font-size: x-large;
    font-weight: bold;
    border: none;
    padding: 0;
    margin-bottom: 16px;
    width: fit-content;
}

.notes-working-indicator {
    position: fixed;
    top: 52px;
    right: 16px
}