.time-local-container {
    margin: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

.time-local-zone {
    margin-left: 4px;
}

.time-local-date-picker {
    width: auto;
    display: inline-block;
}